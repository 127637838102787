<template>
  <layout-vertical>
    <template slot="bread-actions">
      <router-view name="bread-actions" v-show="!isLoading" />
    </template>
    <div>
      <router-view />
    </div>

    <!-- 
        <app-customizer
            v-if="showCustomizer"
            slot="customizer"
        /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import { mapGetters } from "vuex";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "app/isLoading",
    }),
  },
};
</script>
